<template>
  <div>
    <navigationTop
      startTitle="平台"
      endTitle=""
      :visibleOption="['categoryCascader']"
      :defaultCategoryValue="[['zhinan'], ['zujian']]"
      brandType="shop"
      @onNaviEvent="onNaviEvent"
    />
    <charts
      v-model="chartData"
      :lineNumber="2"
    >
      <template v-slot:header>
        <echartCondition
          :topNum="topNum"
          :resetTimes="resetTimes"
          @setCheck="setCheck"
          @setTop="setChart"
        ></echartCondition>
      </template>
      <template v-slot:summary>
        <dataSummary
          header="商品销售金额"
          :toolTip="goodsAmountTooltip"
          :text="toThousands(chartData.sales_amount_total)"
          type="amount"
        />
        <dataSummary
          header="商品销售件数"
          :toolTip="goodsNumTooltip"
          :text="toThousands(chartData.sales_num_total)"
          type="number"
          unit="件"
        />
      </template>
    </charts>

    <chartTable :tableData="tableData" downloadName="商品表" :resetTimes="resetTimes" :firstColumns="firstColumns">
      <template v-slot:search>
        <tableCondition
          :topNum="topNum"
          :resetTimes="resetTimes"
          @setData="setTableData"
        ></tableCondition>
      </template>
      <template v-slot:screen>
        <openTable :tableData="tableData" :firstColumns="firstColumns" :resetTimes="resetTimes"></openTable>
      </template>
    </chartTable>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, ref } from 'vue'

import charts from '@/components/charts/charts'
import axios from '@/utils/request'
import chartTable from '@/components/chart-table/index'
import openTable from '@/components/chart-table/open-table.vue'
import navigationTop from '@/components/navigation-top'
import echartCondition from '@/components/charts/echart-condition'
import { chartsConditionFn } from '@/components/charts/charts-condition-fn'
import tableCondition from '@/components/chart-table/table-condition'
import dataSummary from '@/components/data-summary'
import { toThousands } from '@/utils/index'
import isInterface from '@/components/Interface-condition'
import debounce from 'lodash/debounce'
import { goodsAmountTooltip, goodsNumTooltip } from '@/components/global-tooltip'

const FIRST_COLUMNS = [
  {
    prop: 'amount',
    label: '销售金额'
  },
  {
    label: '销售件数',
    prop: 'num'
  },
  {
    label: '成交价',
    prop: 'avg',
    tooltip: '通过商品页面原价，叠加全部促销活动后，得到的最低到手价'
  },
  {
    label: '营销透视',
    prop: 'youhui',
    tooltip: '可查看所选时间范围内，商品参与的全部促销活动'
  }
]

export default defineComponent({
  name: 'GoodsRank',
  components: {
    charts,
    chartTable,
    navigationTop,
    echartCondition,
    tableCondition,
    dataSummary,
    openTable
  },
  setup () {
    const chartData = reactive({})
    let setChartCondition = {}
    let setTableCondition = { start_time: '', end_time: '' }
    const chartCondition = reactive({ amount: true, number: true })
    let constData = {}
    const tableObj = reactive({})

    const getChartData = debounce(() => {
      if (!isInterface(setChartCondition)) return
      delete setChartCondition.brand_ids
      axios
        .get('/api/v1/product_data/statistics', { params: setChartCondition, loading: true })
        .then((res) => {
          if (res && res.error_code === 0) {
            constData = JSON.parse(JSON.stringify(res.data))
            Object.keys(res.data).forEach((d) => {
              chartData[d] = res.data[d]
            })
            chartsConditionFn(chartCondition, constData, chartData)
          }
        })
    }, 300)

    const tableData = reactive({})
    const getTableData = debounce(() => {
      if (!isInterface(setTableCondition)) return
      delete setTableCondition.brand_ids
      axios
        .get('/api/v1/product_data/data_list', { params: setTableCondition, loading: true })
        .then((res) => {
          if (res && res.error_code === 0) {
            Object.keys(res.data).forEach(key => {
              tableData[key] = res.data[key]
            })
          }
        })
    }, 300)

    const topNum = ref(0)
    axios
      .get('/api/v1/customer/get_top_info', { params: { type: 'goods' } })
      .then((res) => {
        if (res && res.error_code === 0) {
          topNum.value = res.data
        }
      })

    watch(tableObj, () => {
      setTableCondition = { ...tableObj }
    })

    // 重置列表查看日期区间
    const resetTimes = reactive({ dateValue: {} })
    const onNaviEvent = (value) => {
      setChartCondition = { ...setChartCondition, ...value }
      setTableCondition = { ...setTableCondition, ...value }
      resetTimes.dateValue = value
      getChartData()
      getTableData()
    }
    const setChart = (value) => {
      setChartCondition = { ...setChartCondition, ...value }
      getChartData()
    }

    const setCheck = (value) => {
      Object.keys(value).forEach((l) => {
        chartCondition[l] = value[l]
      })
      chartsConditionFn(value, constData, chartData)
    }

    const setTableData = (value) => {
      setTableCondition = { ...setTableCondition, ...value }

      getTableData()
    }

    return {
      firstColumns: FIRST_COLUMNS,
      chartCondition,
      chartData,
      tableData,
      tableObj,
      resetTimes,
      setTableData,
      onNaviEvent,
      setCheck,
      toThousands,
      setChart,
      goodsAmountTooltip,
      goodsNumTooltip,
      topNum
    }
  }
})
</script>
